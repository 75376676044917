import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Checkbox, FormControlLabel, LinearProgress, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// import IntegrationReactSelect from '../../components/IntegrationReactSelect';
import { setSearchCriteria, searchAction, searchError } from '../../store/actions/search';
// import { civiURLBuild } from '../../store/actions/helpers';

const styles = theme => ({
    container: {
        width: 400,
        margin: '0 auto',
    },
});

// Basic Login form: https://medium.com/technoetics/create-basic-login-forms-using-create-react-app-module-in-reactjs-511b9790dede
class Search extends Component {

    keyPress = e => {
        if(e.keyCode === 13){
            // alert("Enter hit");
           this.search();
        }
     }

    search = () => {
        // console.log(this.props);
        // return;
        const { firstname, lastname, cellphone, email, currentEvents } = this.props;
        if (
            firstname.length===0 && 
            lastname.length===0 && 
            cellphone.length===0 && 
            email.length===0
        ) {
            this.props.searchError(new Error('Must search by at least one type'));
            return;
        }

        if (
            email.length>0 &&
            firstname.length===0 && 
            lastname.length===0
        ) {
            this.props.searchError(new Error('Must specify a first or last name when searching by email'));
            return;
        }

        if (
            cellphone.length>0 &&
            firstname.length===0 && 
            lastname.length===0
        ) {
            this.props.searchError(new Error('Must specify a first or last name when searching by cell phone'));
            return;
        }

        this.props.search({ firstname, lastname, cellphone, email, currentEvents });
    }

    // loadEvents = inputValue => {

    //     if (inputValue.length===0) return [];

    //     return fetch(civiURLBuild('Event','get', {
    //         title: {
    //             LIKE: "%" + inputValue + "%",
    //         },
    //         sequential: 1,
    //         options: {limit:0},
    //     }))
    //     .then(response => response.json())
    //     .then(json => {
    //         if (json.is_error) {
    //             throw Error(json.error_message);
    //         }
    //         return json.values;
    //     })
    //     .then(events => events.map(event => ({ value: event.id, label: event.title, })));
    // }
  
    render() {

        // redirect to results if we have them
        if (this.props.results.length>0) {
            return <Redirect to="/results" />;
        }

        const { classes, setCriteria } = this.props;

        const style = {
            margin: 15,
        };

        const loadingStyleProps ={
            opacity: this.props.loading ? 1 : 0,
        };

        return (
            <div className="Search">
                <div className="loading" style={loadingStyleProps}>
                    <LinearProgress mode="indeterminate" color="secondary" />
                </div>
                <div className={classes.container}>
                    <br />
                    <Typography variant="h3" gutterBottom>Search</Typography>
                    {this.props.error ?
                        <Typography variant="h5" color="error" gutterBottom>{this.props.error}</Typography>
                    : ''}
                    {/* <IntegrationReactSelect 
                        label="Events"
                        isMulti={true}
                        onChange = {e => setCriteria('events', e)}
                        placeholder="Select Events"
                        loadOptions={this.loadEvents}
                        disabled={this.props.loading}
                        fullWidth={true}
                    /> */}
                    <TextField
                        label="First Name"
                        onChange = {e => setCriteria('firstname', e.target.value.trim())}
                        value={this.props.firstname}
                        onKeyDown={this.keyPress}
                        disabled={this.props.loading}
                        fullWidth={true}
                    />
                    <br/>
                    <TextField
                        label="Last Name"
                        onChange = {e => setCriteria('lastname', e.target.value.trim())}
                        value={this.props.lastname}
                        onKeyDown={this.keyPress}
                        disabled={this.props.loading}
                        fullWidth={true}
                    />
                    <br/>
                    <TextField
                        label="Email"
                        onChange = {e => setCriteria('email', e.target.value.trim())}
                        value={this.props.email}
                        onKeyDown={this.keyPress}
                        disabled={this.props.loading}
                        fullWidth={true}
                    />
                    <br/>
                    <TextField
                        label="Cell Phone"
                        onChange = {e => setCriteria('cellphone', e.target.value.trim())}
                        value={this.props.cellphone}
                        onKeyDown={this.keyPress}
                        disabled={this.props.loading}
                        fullWidth={true}
                    />
                    <br/>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.currentEvents}
                                onChange={e => setCriteria('currentEvents', e.target.checked)}
                                value={true}
                            />
                        }
                        label="Only Current Events"
                    />
                    <Button variant="contained" raised="true"
                        color="primary"
                        style={style} 
                        onClick={() => this.search()}
                        disabled={this.props.loading}
                    >Search</Button>
                </div>
            </div> 
        );
    }
}

// Redux functions to connnect the component to the store.
const mapStateToProps = state => {
    const { search } = state;
    const { criteria } = search;
    return {
        error: search.error,
        loading: search.isFetching,
        firstname: criteria.firstname,
        lastname: criteria.lastname,
        email: criteria.email,
        cellphone: criteria.cellphone,
        currentEvents: criteria.currentEvents,
        results: search.results,
    };
}
  
const mapDispatchToProps = dispatch => {
    // console.log("App mapDispatchToProps", dispatch);
    return {
        search: criteria => dispatch(searchAction(criteria)),
        searchError: err => dispatch(searchError(err)),
        setCriteria: (field, value) => dispatch(setSearchCriteria(field, value)),
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles, { widthTheme: true })(Search)
);